.contact-container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.contact-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.contact-links > a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-links img {
    max-width: 150px; 
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.contact-links img:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .contact-links {
        flex-direction: column;
    }
}