html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    scroll-padding-top: 10rem;
}

body {
    font-family: 'futura', sans-serif;
    font-size: 2.2rem;
    padding: 0;
    margin: 0;
}

.home-page {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

h2,
h3 {
    color: 	#D2042D;
}

@media (max-width: 768px) {
    body {
        font-size: 1.8rem;
    }

    h2{
        font-size: 1.8rem;
        font-weight: 800;
    }

    h3 {
        font-size: 1.6rem;
    }

    p {
        font-size: 1.6rem;
        line-height: 1.4;
    }


}