/* Style for the navigation bar */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: white;
}

.logo {
    display: flex;
    align-items: center;
}

.logo h1 {
    margin-right: 10px;
}

.logo p {
    margin: 0;
    text-align: center;
}

.menu ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu ul li {
    margin-left: 20px;
}

.menu ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.menu ul li a:hover {
    color: #D2042D;
}

/* Media query for smaller screens (e.g., mobile devices) */
@media (max-width: 768px) {
    .logo {
        display: none;
    }

    .menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s ease-in-out;
    }

    .menu.open {
        max-height: 300px;
        /* Adjust height based on content */
    }

    .menu ul {
        flex-direction: column;
        width: 100%;
    }

    .menu ul li {
        margin: 10px 0;
        text-align: center;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .hamburger .bar {
        width: 25px;
        height: 3px;
        background-color: black;
        margin: 4px 0;
        transition: 0.4s;
    }

    .hamburger.open .bar:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger.open .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.open .bar:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }
}

@media (min-width: 769px) {
    .hamburger {
        display: none;
    }
}