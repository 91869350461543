.projects-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.project {
    display: flex;
    justify-content: space-between;
}

.project-content {
    flex: 1;
    padding-right: 20px;
}

.project-text {
    flex: 1;
}

.project img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .project {
        flex-direction: column;
    }
}