.about-header {
    max-width: 50ch;
}

.about-title {
    margin-top: 2.5rem;
}

img {
    max-width: 100%;
    height: auto;
}

.image-collage {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 300px);
    gap: 10px;
}

.image-collage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-collage img:nth-child(1) {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}

.image-collage img:nth-child(2) {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
}

.image-collage img:nth-child(3) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}

@media (max-width: 768px) {
    .image-collage {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 300px);
    }

    .image-collage img:nth-child(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .image-collage img:nth-child(2) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .image-collage img:nth-child(3) {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}