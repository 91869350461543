.footer {
  background-color: #232b32;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin: 0;
}

.footer-icon {
  background-color: white;
  width: 75px;
  margin: 0 10px;
  padding: 10px;
  border-radius: 50%;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.footer-links > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links img {
  height: auto;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.footer-links img:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .footer-icon {
    width: 50px;
  }
}
