.intro {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.intro-text {
    max-width: 42ch;
}

.intro-text h2 {
    margin: 0 0 10px 0;
    font-size: 2rem;
}

.intro-text p {
    font-size: 2.8rem;
    line-height: 1.5;
    font-weight: 500;
}

.intro-picture {
    display: flex;
    justify-content: end;
    align-items: center;
}

.intro-picture img {
    max-height: 600px;
    object-fit: contain; 
}

.intro-header {
    color: 	#D2042D;
}

@media (max-width: 768px) {
    .intro {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .intro-text p {
        font-size: 2rem;
    }

    .intro-picture {
        justify-content: center;
        
    }
}